.c-profile {
    margin-bottom: 60px;
    float: left;
    width: 100%;
}

.c-profile__image {
    border-radius: 300px;
    width: 80px;
}

.c-profile__text {
    height: 80px;
    font-weight: 900;
    color: #4b4c49;
    margin-left: 15px;
    padding-top: 15px;
}

.c-hero {
    margin-bottom: 80px;
    float: left;
    width: 100%;
}

.c-go-to {
    margin-bottom: 80px;
}

.c-go-to .btn {
    background-color: transparent;
    border-color: #ff5758;
}

.c-go-to .btn:hover {
    background-color: #ff5758;
}

.c-hero__text {
    font-weight: 900;
    font-size: 30px;
    line-height: 50px;
}

.c-experience {

}

.c-experience__list {

}

.c-experience-list__item {
    background-color: #050505;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 30px;
    float: left;
    width: 100%;
}

.c-experience-list-item__left {
    float: left;
}

.c-experience-list-item-left__time {
    color: #4b4c49;
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
}

.c-experience-list-item-left__function {
    font-weight: 600;
    display: block;
}

.c-experience-list-item__right {
    float: right;
}

.c-experience-list-item-right__company {
    color: #4b4c49;
    font-weight: 600;
}

.c-vert-heading__title {
    position: absolute;
    top: 35%;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-weight: 700;
}

.c-hor-heading__title {
    font-weight: 700;
    float: left; 
    width: 100%;
}

.c-devicons i {
    float: left;
    margin-right: 15px;
    margin-bottom: 15px;
    font-size: 45px;
}

.c-contact ul {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.c-contact ul li {
    width: 100%;
    float: left;
    font-weight: 600;
    margin-bottom: 15px;
}

.c-contact ul li .float-left {
    color: #4b4c49;
}

.c-contact ul li .float-right a {
    color: #ffffff;
}