svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

circle {
    transform-origin: 50% 50%;
    transform: scale(0);
    transition: transform 200ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

text {
    font-size: 1.1rem;
    text-transform: uppercase;
    text-anchor: middle;
    letter-spacing: 1px;
    font-weight: 600;
}

.svg-text {
    fill: #545a64;
}
  
.svg-masked-text {
    fill: white;
}

image {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transition: -webkit-transform 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: -webkit-transform 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: transform 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: transform 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94), -webkit-transform 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.items {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-flow: row wrap;
    -webkit-box-pack: center;
            justify-content: center;
}
  
.item {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
            align-items: center;
    -webkit-box-pack: center;
            justify-content: center;
    position: relative;
    overflow: hidden;
    height: 200px;
    margin-bottom: 30px;
    cursor: pointer;
    background-color: #050505;
    border-radius: 2px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.02), inset 0 0px 0px 1px rgba(0, 0, 0, 0.07);
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
}

.item a {
    display: block;
    width: 100%;
    height: 100%;
}
  
.item:hover circle,
.item:hover image {
    -webkit-transform: scale(1);
            transform: scale(1);
}

  button {
    width: 12px;
    height: 12px;
    border: none;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
    border-radius: 1px;
  }
  button.dark {
    background-color: #2f3238;
  }
  button.light {
    background-color: #f9f9f9;
  }
  
  .options {
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .options button {
    margin-left: .5rem;
  }
  
  .light {
    background-color: #f9f9f9;
    color: #1a1a1a;
  }

  .light p {
    color: rgba(26, 26, 26, 0.5);
  }

  .light .item {
    background: #f5f5f5;
  }

  .light .svg-text {
    fill: rgba(0, 0, 0, 0.1);
  }
  